import React from "react";
import PropTypes from "prop-types";
import StreamlinedAssigner from "./StreamlinedAssigner";

class StreamlinedAssignmentBtn extends React.Component {
  render() {
    const { order } = this.props;

    if (!order.uiState.allowStreamlinedAssignment) {
      return null;
    }
    return (
      <StreamlinedAssigner
        order={order}
        render={({ sendToPartner }) => (
          <button
            className="button primary"
            onClick={() => {
              if (window.confirm("Send to partner?")) {
                sendToPartner();
              }
            }}
          >
            Send to Partner
          </button>
        )}
      />
    );
  }
}

StreamlinedAssignmentBtn.propTypes = {
  order: PropTypes.object
};

export default StreamlinedAssignmentBtn;
